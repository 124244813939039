import * as React from "react"
import { graphql, Link } from "gatsby"
import Marquee from "react-fast-marquee"
import culturePageHero from'../images/hero-community-page.png'
import column1 from '../images/platforms/tiktok.jpg'
import column3 from '../images/platforms/instagram.jpg'
import mission from "../images/community/mission.jpg"
import community1 from "../images/community/event-community-1.jpg"
import community2 from "../images/community/event-community-2.gif"
import sizzle from '../videos/sizzle_2.mp4'

import InstagramIcon from "../images/icons/instagram.svg"
import TwitterIcon from "../images/icons/twitter.svg"
import YouTubeIcon from "../images/icons/youtube.svg"
import TikTokIcon from "../images/icons/tiktok.svg"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Community = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const talents = data.webiny.listTalent.data
  return (
    <Layout location={location} title={siteTitle}>
      
      <Seo title="Community" />

      <div className="bg-gray-300">

      <div className="">
        <div className="h-120 md:h-[51.25rem] overflow-hidden relative lg:bg-none bg-cover bg-center" style={{ backgroundImage: `url(${culturePageHero})` }}>
          <img src={culturePageHero} className="hidden lg:inline-block w-full z-0" />
          <div className="px-5 bottom-5 lg:p-0 absolute lg:bottom-10 lg:left-5 z-30">
            <div className="w-full lg:pl-2 ">
              <h1 className="knockoutFont text-6xl md:text-8xl  uppercase">Our Community Is Everything</h1>
            </div>
          </div>
          <div className="h-72 lg:h-96 bg-gradient-to-t from-black absolute w-full bottom-0 z-20" />
        </div>
      </div>

      <Marquee
        autoFill 
        pauseOnHover
        gradient={false} 
        className="w-full uppercase bg-gray-300 text-black TitlingGothicFBWideFont py-2 border-b-2 border-t-2 border-black">
          <div className="p-2">&nbsp;&nbsp;THE CONVERSATION IS ALWAYS-ON&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//</div>
      </Marquee>

      <div className="lg:container mx-auto p-5 lg:py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-8 lg:gap-9 xl:gap-10">
          <div className="w-full h-140 bg-slate-200 bg-center bg-cover border border-black md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition" style={{ backgroundImage: `url(${column1})` }} />
          <div className="w-full h-140 bg-white text-black border border-black p-7 relative md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition">
            <h2 className="knockoutFont text-6xl uppercase">Everywhere</h2>
            <div className="absolute right-7 left-7 bottom-10">
              <h3 className="knockoutFont text-9xl uppercase">88M+</h3>
              <p className="knockoutFont text-6xl uppercase">IMPRESSIONS</p>
              <p className="text-md leading-5 mt-0">We create moments that allow the community to feel like they're a part of the conversation on their platform of choice. We have fun, lean into what's trending with a unique POV that the Blerd culture understands.</p>
            </div>
          </div>
          <div className="w-full h-140 bg-slate-200 bg-center bg-cover border border-black md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition" style={{ backgroundImage: `url(${column3})` }} />
          <div className="w-full h-140 lg:h-104 2xl:h-125 bg-white text-black border border-black p-7 relative md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition">
            <h2 className="knockoutFont text-6xl uppercase">All Together</h2>
            <div className="absolute right-7 left-7 bottom-10">
              <h3 className="knockoutFont text-9xl lg:text-8xl 2xl:text-9xl uppercase">34M+</h3>
              <p className="knockoutFont text-5xl lg:text-5xl 2xl:text-6xl uppercase">SUBSCRIBERS</p>
              <p className="text-md leading-5 mt-4">GWB hosts weekly livestreams alongside a program of VOD content. We hang out with talent in ways that their fans have often never seen before. From playing games and talking music, to lively cultural debates, and heartfelt storytelling.</p>
            </div>
          </div>
          <div className="w-full h-auto lg:h-104 2xl:h-125 bg-slate-200 border border-black md:col-span-2 overflow-hidden md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition">
            <a href="https://www.youtube.com/watch?v=LfTuN3qt5tg" target="_blank">
            <video  
              autoPlay 
              loop 
              muted 
              playsInline 
              className="w-screen"
            >
              <source
                src={sizzle}
                type="video/mp4"
              />
            </video>
            </a>
          </div>
          <div className="w-full h-140 bg-slate-200 bg-center bg-cover lg:bg-center border border-black md:col-span-2 lg:col-span-1 md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition" style={{ backgroundImage: `url(${community2})` }} />
          <div className="w-full h-140 bg-slate-200 bg-center bg-cover border border-black md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition" style={{ backgroundImage: `url(${community1})` }} />
          <div className="w-full h-140 bg-white text-black border border-black p-7 relative md:hover:shadow-solid ease-in-out md:hover:scale-105 duration-500 transition">
            <h2 className="knockoutFont text-6xl uppercase">And in-Person</h2>
            <div className="absolute right-7 left-7 bottom-10">
              <h3 className="knockoutFont text-8xl uppercase">7 EVENTS</h3>
              <p className="knockoutFont text-6xl uppercase">AND COUNTING</p>
              <p className="text-md leading-5 mt-0">We get out of the studio regularly. We attend conventions where fans gather. We go onto HBCU campuses with our brand partners. And we host tournaments and give-back programs that celebrate the best in being part of a community.</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-neon-green py-5 md:py-20">
        <div class="lg:container mx-auto px-5 lg:p-10 grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="">
            <div className="">      
              <img 
                src={mission} 
                className="w-full float-right" 
                alt="About Gaming While Black Team" 
              />
            </div>
          </div>
          <div className="">
            <h2 className="tracking-tight font-medium text-9xl lg:text-10xl xl:text-12xl knockoutFont uppercase text-black pb-10">JOIN THE COMMUNITY</h2>
            <ul className="pb-10 md:pb-0 flex space-x-0">
              <li className="px-5 flex items-center justify-center">
                <Link to="https://www.instagram.com/gwbshow/" target="_blank" className="">
                  <img src={InstagramIcon} className="w-8 sm:w-10" />
                </Link>
              </li>
              <li className="px-5 flex items-center justify-center">
                <Link to="https://twitter.com/gwbshow" target="_blank" className="">
                  <img src={TwitterIcon} className="w-10 sm:w-12" />
                </Link>
              </li>
              <li className="px-5 flex items-center justify-center">
                <Link to="https://www.youtube.com/channel/UCj2-UosnU6yLZ3ImtV_FvdQ" target="_blank" className="">
                  <img src={YouTubeIcon} className="w-10 sm:w-12" />
                </Link>
              </li>
              <li className="px-5 flex items-center justify-center">
                <Link to="https://www.tiktok.com/@gwbshow" target="_blank" className="">
                  <img src={TikTokIcon} className="w-7 sm:w-9" />
                </Link>
              </li>
            </ul>
          </div>
          
        </div>
      </div> */}

      <div className="text-black bg-neon-green">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div className="md:py-10 lg:py-0">
                <img src={mission} className="w-full" />
              </div>
              <did className="lg:pt-10 px-5 md:py-10 lg:py-20 lg:max-w-2xl">
              <h2 className="tracking-tight font-medium text-9xl lg:text-10xl xl:text-12xl knockoutFont uppercase text-black pb-10">JOIN THE COMMUNITY</h2>
                <div className="">
                <ul className="pb-10 md:pb-0 flex space-x-0">
                  <li className="px-5 flex items-center justify-center">
                    <Link to="https://www.instagram.com/gwbshow/" target="_blank" className="">
                      <img src={InstagramIcon} className="w-8 sm:w-10" />
                    </Link>
                  </li>
                  <li className="px-5 flex items-center justify-center">
                    <Link to="https://twitter.com/gwbshow" target="_blank" className="">
                      <img src={TwitterIcon} className="w-10 sm:w-12" />
                    </Link>
                  </li>
                  <li className="px-5 flex items-center justify-center">
                    <Link to="https://www.youtube.com/channel/UCj2-UosnU6yLZ3ImtV_FvdQ" target="_blank" className="">
                      <img src={YouTubeIcon} className="w-10 sm:w-12" />
                    </Link>
                  </li>
                  <li className="px-5 flex items-center justify-center">
                    <Link to="https://www.tiktok.com/@gwbshow" target="_blank" className="">
                      <img src={TikTokIcon} className="w-7 sm:w-9" />
                    </Link>
                  </li>
                </ul>
                </div>
              </did>
            </div>
          </div>

      </div>

    </Layout>
  )
}

export default Community

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      listTalent(sort: createdOn_ASC) {
        data {
          name
          description
          visibility
          image
          entryId
        }
      }
      listTalent(sort: createdOn_ASC) {
        data {
          name
          description
          visibility
          image
          entryId
        }
      }
    }
  }
`
